var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white p-20 round"},[_c('div',{staticClass:"container mx-auto text-left"},[(_vm.hasPremission && _vm.loaded)?_c('div',{staticClass:"mb-40"},[([6, 7].includes(_vm.role))?_c('div',{staticClass:"text-right"},[(!_vm.edit)?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){_vm.edit = true}}},[_vm._v("修改")]):_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){_vm.edit = false}}},[_vm._v("取消")])],1):_vm._e(),(!_vm.edit)?_c('div',[_c('div',{staticClass:"text-bold mb-10",staticStyle:{"font-size":"32px","line-height":"40px","word-wrap":"break-word"}},[_vm._v(" "+_vm._s(_vm.detail.Title)+" ")]),_c('div',{staticClass:"mb-40"},[_c('a-space',{staticClass:"text-xs text-999"},[_c('span',[_c('span',[_vm._v("来源：")]),_c('span',[_vm._v(_vm._s(_vm.detail.Source))])]),_c('span',[_vm._v("|")]),_c('span',[_vm._v(" "+_vm._s(_vm.detail.Region.Name)+" ")]),_c('span',[_vm._v("|")]),_c('span',[_vm._v(" "+_vm._s(_vm.detail.Type.Name)+" ")]),_c('span',[_vm._v("|")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("formatterTime")(_vm.detail.time,"YYYY-MM-DD"))+" ")])])],1),_c('div',{domProps:{"innerHTML":_vm._s(_vm.detail.Desc)}}),_vm._m(0),_c('div',_vm._l((_vm.detail.Attachments),function(attach){return _c('div',{key:attach.ID,staticClass:"flex items-center"},[_c('a-icon',{staticClass:"mr-10",attrs:{"type":"link"}}),_c('a',{attrs:{"href":attach.url,"target":"blank"}},[_vm._v(_vm._s(attach.name))])],1)}),0)]):_c('div',[_c('a-form-model',{ref:"container",attrs:{"model":_vm.detail,"labelCol":{ span: 5 },"wrapperCol":{ span: 11 },"labelAlign":"left","colon":false}},[_c('div',{staticClass:"mb-40"},[_c('div',[_c('gf-form-item',{attrs:{"prop":"Title","label":"标题","rules":[
                  {
                    required: true,
                    message: '请输入',
                    trigger: ['change', 'blur'],
                  } ]}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.detail.Title),callback:function ($$v) {_vm.$set(_vm.detail, "Title", $$v)},expression:"detail.Title"}})],1),_c('gf-form-item',{attrs:{"prop":"TypeId","rules":[
                  {
                    required: true,
                    message: '请输入',
                    trigger: ['change', 'blur'],
                  } ],"label":"类型","wrapperCol":{ span: 7 }}},[_c('a-select',{attrs:{"placeholder":"请选择","disabled":_vm.disabled},model:{value:(_vm.detail.TypeId),callback:function ($$v) {_vm.$set(_vm.detail, "TypeId", $$v)},expression:"detail.TypeId"}},_vm._l((_vm.options.PolicyType.filter(function (item) {
                      if ([1, 3, 4].includes(_vm.detail.TypeId)) {
                        return [1, 3, 4].includes(item.ID);
                      } else {
                        return ![1, 3, 4].includes(item.ID);
                      }
                    })),function(option){return _c('a-select-option',{key:option.ID},[_vm._v(" "+_vm._s(option.Name)+" ")])}),1)],1),_c('gf-form-item',{attrs:{"prop":"Source","rules":{
                  required: true,
                  message: '请选择',
                  trigger: ['change', 'blur'],
                },"label":"来源","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"placeholder":"请输入","disabled":_vm.disabled},model:{value:(_vm.detail.Source),callback:function ($$v) {_vm.$set(_vm.detail, "Source", $$v)},expression:"detail.Source"}})],1),_c('gf-form-item',{attrs:{"prop":"PublicTime","rules":[
                  {
                    required: true,
                    message: '请输入',
                    trigger: ['change', 'blur'],
                  } ],"label":"发布时间"}},[_c('a-date-picker',{attrs:{"placeholder":"请选择年/月/日","valueFormat":"x","size":"large"},model:{value:(_vm.detail.PublicTime),callback:function ($$v) {_vm.$set(_vm.detail, "PublicTime", $$v)},expression:"detail.PublicTime"}})],1),_c('gf-form-item',{attrs:{"prop":"regionId","wrapperCol":{ span: 7 },"rules":[
                  {
                    required: true,
                    message: '请输入',
                    trigger: ['change', 'blur'],
                  } ],"label":"地区"}},[_c('a-select',{attrs:{"placeholder":"请选择","disabled":_vm.disabled},model:{value:(_vm.detail.regionId),callback:function ($$v) {_vm.$set(_vm.detail, "regionId", $$v)},expression:"detail.regionId"}},_vm._l((_vm.options.PolicyRegion),function(option){return _c('a-select-option',{key:option.ID},[_vm._v(" "+_vm._s(option.Name)+" ")])}),1)],1),_c('gf-form-item',{attrs:{"prop":"Attachments","label":"上传附件"}},[_c('file-upload',{attrs:{"size":20,"accept":".pdf,.docx,.doc,.png,.jpg,.jpeg,.xls,.xlsx"},model:{value:(_vm.detail.Attachments),callback:function ($$v) {_vm.$set(_vm.detail, "Attachments", $$v)},expression:"detail.Attachments"}})],1),_c('gf-form-item',{attrs:{"prop":"Desc","wrapperCol":{ span: 18 },"rules":[
                  {
                    required: true,
                    message: '请输入',
                    trigger: ['change', 'blur'],
                  } ],"label":"正文"}},[_c('gf-editor',{model:{value:(_vm.detail.Desc),callback:function ($$v) {_vm.$set(_vm.detail, "Desc", $$v)},expression:"detail.Desc"}})],1)],1)])]),_c('div',{staticClass:"text-center"},[_c('a-space',{attrs:{"size":20}},[_c('a-button',{attrs:{"size":"large","type":"primary","ghost":""},on:{"click":_vm.back}},[_vm._v("取消")]),_c('a-button',{attrs:{"size":"large","type":"primary"},on:{"click":_vm.submit}},[_vm._v(" 提交 ")])],1)],1)],1)]):(_vm.loaded && !_vm.hasPremission)?_c('div',[_vm._m(1)]):_vm._e(),_c('refuse-modal',{attrs:{"required":_vm.status === 3},on:{"confirm":_vm.confirm},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}}),_c('div',{staticClass:"text-center mt-40"},[_c('a-space',[(_vm.detail.AuditStatus === 1)?_c('a-button',{attrs:{"type":"primary","ghost":""},on:{"click":function($event){return _vm.open(3)}}},[_vm._v("审核拒绝")]):_vm._e(),(_vm.detail.AuditStatus === 1)?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.open(2)}}},[_vm._v("审核通过")]):_vm._e()],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-lg font-bold mt-40 mb-20"},[_c('span',{staticClass:"mr-10 text-primary align-middle"},[_vm._v("|")]),_c('span',{staticClass:"align-middle text-111 font-bold"},[_vm._v("附件")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col justify-center items-center",staticStyle:{"height":"calc(100vh - 72px)"}},[_c('img',{attrs:{"src":require("@/assets/images/noPromission.svg"),"alt":""}}),_c('div',{staticClass:"text-999 text-sm"},[_vm._v("抱歉，您没有权限访问该页面")])])}]

export { render, staticRenderFns }