<template>
  <div class="bg-white p-20 round">
    <div class="container mx-auto text-left">
      <div v-if="hasPremission && loaded" class="mb-40">
        <div class="text-right" v-if="[6, 7].includes(role)">
          <a-button type="link" @click="edit = true" v-if="!edit"
            >修改</a-button
          >
          <a-button type="link" @click="edit = false" v-else>取消</a-button>
        </div>
        <div v-if="!edit">
          <div
            style="font-size: 32px; line-height: 40px; word-wrap: break-word"
            class="text-bold mb-10"
          >
            {{ detail.Title }}
          </div>
          <div class="mb-40">
            <a-space class="text-xs text-999">
              <span>
                <span>来源：</span>
                <span>{{ detail.Source }}</span>
              </span>
              <span>|</span>
              <span>
                {{ detail.Region.Name }}
              </span>
              <span>|</span>
              <span>
                {{ detail.Type.Name }}
              </span>
              <span>|</span>
              <span>
                {{ detail.time | formatterTime("YYYY-MM-DD") }}
              </span>
            </a-space>
          </div>
          <div v-html="detail.Desc"></div>
          <div class="text-lg font-bold mt-40 mb-20">
            <span class="mr-10 text-primary align-middle">|</span>
            <span class="align-middle text-111 font-bold">附件</span>
          </div>
          <div>
            <div
              class="flex items-center"
              v-for="attach in detail.Attachments"
              :key="attach.ID"
            >
              <a-icon type="link" class="mr-10" />
              <a :href="attach.url" target="blank">{{ attach.name }}</a>
            </div>
          </div>
        </div>
        <div v-else>
          <a-form-model
            ref="container"
            :model="detail"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 11 }"
            labelAlign="left"
            :colon="false"
          >
            <div class="mb-40">
              <div>
                <gf-form-item
                  prop="Title"
                  label="标题"
                  :rules="[
                    {
                      required: true,
                      message: '请输入',
                      trigger: ['change', 'blur'],
                    },
                  ]"
                >
                  <gf-re-input
                    :disabled="disabled"
                    v-model="detail.Title"
                  ></gf-re-input>
                </gf-form-item>
                <gf-form-item
                  prop="TypeId"
                  :rules="[
                    {
                      required: true,
                      message: '请输入',
                      trigger: ['change', 'blur'],
                    },
                  ]"
                  label="类型"
                  :wrapperCol="{ span: 7 }"
                >
                  <a-select
                    placeholder="请选择"
                    :disabled="disabled"
                    v-model="detail.TypeId"
                  >
                    <a-select-option
                      v-for="option in options.PolicyType.filter((item) => {
                        if ([1, 3, 4].includes(detail.TypeId)) {
                          return [1, 3, 4].includes(item.ID);
                        } else {
                          return ![1, 3, 4].includes(item.ID);
                        }
                      })"
                      :key="option.ID"
                    >
                      {{ option.Name }}
                    </a-select-option>
                  </a-select>
                </gf-form-item>
                <gf-form-item
                  prop="Source"
                  :rules="{
                    required: true,
                    message: '请选择',
                    trigger: ['change', 'blur'],
                  }"
                  label="来源"
                  :wrapperCol="{ span: 7 }"
                >
                  <gf-re-input
                    placeholder="请输入"
                    :disabled="disabled"
                    v-model="detail.Source"
                  ></gf-re-input>
                </gf-form-item>
                <gf-form-item
                  prop="PublicTime"
                  :rules="[
                    {
                      required: true,
                      message: '请输入',
                      trigger: ['change', 'blur'],
                    },
                  ]"
                  label="发布时间"
                >
                  <a-date-picker
                    placeholder="请选择年/月/日"
                    valueFormat="x"
                    size="large"
                    v-model="detail.PublicTime"
                  ></a-date-picker>
                </gf-form-item>

                <gf-form-item
                  prop="regionId"
                  :wrapperCol="{ span: 7 }"
                  :rules="[
                    {
                      required: true,
                      message: '请输入',
                      trigger: ['change', 'blur'],
                    },
                  ]"
                  label="地区"
                >
                  <a-select
                    placeholder="请选择"
                    :disabled="disabled"
                    v-model="detail.regionId"
                  >
                    <a-select-option
                      v-for="option in options.PolicyRegion"
                      :key="option.ID"
                    >
                      {{ option.Name }}
                    </a-select-option>
                  </a-select>
                </gf-form-item>
                <gf-form-item
                  prop="Attachments"
                  label="上传附件"
                >
                  <file-upload
                    :size="20"
                    accept=".pdf,.docx,.doc,.png,.jpg,.jpeg,.xls,.xlsx"
                    v-model="detail.Attachments"
                  ></file-upload>
                </gf-form-item>
                <gf-form-item
                  prop="Desc"
                  :wrapperCol="{ span: 18 }"
                  :rules="[
                    {
                      required: true,
                      message: '请输入',
                      trigger: ['change', 'blur'],
                    },
                  ]"
                  label="正文"
                >
                  <!-- <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="500"
                v-model="detail.Desc"
              ></gf-re-textarea> -->
                  <gf-editor v-model="detail.Desc"></gf-editor>
                </gf-form-item>
              </div>
            </div>
          </a-form-model>
          <div class="text-center">
            <a-space :size="20">
              <a-button size="large" type="primary" ghost @click="back"
                >取消</a-button
              >
              <a-button size="large" type="primary" @click="submit">
                提交
              </a-button>
            </a-space>
          </div>
        </div>
      </div>
      <div v-else-if="loaded && !hasPremission">
        <div
          class="flex flex-col justify-center items-center"
          style="height: calc(100vh - 72px)"
        >
          <img src="@/assets/images/noPromission.svg" alt="" />
          <div class="text-999 text-sm">抱歉，您没有权限访问该页面</div>
        </div>
      </div>
      <refuse-modal
        :required="status === 3"
        v-model="visible"
        @confirm="confirm"
      ></refuse-modal>
      <div class="text-center mt-40">
        <a-space>
          <a-button
            @click="open(3)"
            type="primary"
            ghost
            v-if="detail.AuditStatus === 1"
            >审核拒绝</a-button
          >
          <a-button
            @click="open(2)"
            type="primary"
            v-if="detail.AuditStatus === 1"
            >审核通过</a-button
          >
        </a-space>
      </div>
    </div>
  </div>
</template>

<script>
import { getPolicy, evaluatePolicy, updateOpPolicy } from "@/api/api/policy";
import moment from "moment";
export default {
  data() {
    return {
      id: "",
      hasPremission: true,
      loaded: false,
      detail: {},
      visible: false,
      status: 0,
      disabled: false,
      edit: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getPolicy(this.id);
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    role() {
      return this.$store.state.role;
    },
  },
  methods: {
    getPolicy(id) {
      getPolicy(id)
        .then((data) => {
          data.time = data.PublicTime;
          data.PublicTime = moment(data.PublicTime);
          this.detail = data;
        })
        .catch(({ code }) => {
          if (code === 403) {
            this.hasPremission = false;
          }
        })
        .finally(() => {
          this.loaded = true;
        });
    },
    open(status) {
      this.status = status;
      this.visible = true;
    },
    confirm(e) {
      evaluatePolicy(this.id, {
        AuditStatus: this.status,
        Feedback: e.feedback,
      })
        .then(() => {
          this.$message.success("审核成功");
          this.getPolicy(this.id);
          this.visible = false;
          this.status = 0;
        })
        .catch(() => {
          this.$message.error("审核失败");
        });
    },
    restore(data) {
      const temp = { ...data };
      temp.Type = this.options.PolicyType[temp.TypeId - 1];
      temp.Region = this.options.PolicyRegion[temp.regionId - 1];
      temp.PublicTime = new Date(+temp.PublicTime).toISOString();
      return temp;
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.container.validate((valid) => {
          if (valid) {
            resolve(this.restore(this.detail));
          } else {
            reject();
          }
        });
      });
    },
    back() {
      this.$router.back();
    },
    submit() {
      this.validate().then((res) => {
        updateOpPolicy(this.id, res)
          .then(() => {
            this.$message.success("更新政策成功");
            this.back();
          })
          .catch(() => {
            this.$message.error("更新政策失败");
          });
      });
    },
  },
};
</script>